import clipboard from './modules/clipboard';
import search from './modules/search';

function initPage() {
  const searchForm = document.querySelector('#js-search-form');
  if (searchForm !== null) {
    search.init(searchForm);
  }

  const blocks = document.querySelectorAll('pre[class^="language-"]');
  if (blocks !== null) {
    clipboard.init(blocks);
    // document.addEventListener('click', (e) => {
    //   if (e.target.closest('button.clipboard-button')) {
    //     clipboard.copyCode(e.target)
    //   }
    // })
  }
}

// 'DOMContentLoaded' may fire before your script has a chance to run,
// so it is wise to check before adding a listener.
// https://developer.mozilla.org/en-US/docs/Web/API/Document/DOMContentLoaded_event
if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', initPage);
} else {
  // 'DOMContentLoaded' has already fired.
  initPage();
}
