/**
 * Add a "Copy Code" button to each code element to automatically
 * copy the respective code in the clipboard of the client.
 */
const clipboard = {
  buttonLabel: 'Copy Code',
  buttonCopiedLabel: 'Code Copied',
  buttonErrorLabel: 'Error',
  init: (blocks) => {
    for (let i = 0; i < blocks.length; i += 1) {
      const button = document.createElement('button');
      button.setAttribute('class', 'clipboard-button');
      button.innerText = clipboard.buttonLabel;
      blocks[i].prepend(button);
      button.addEventListener('click', async () => {
        await clipboard
          .copyCode(blocks[i])
          .then((result) => {
            if (result === true) {
              button.innerText = clipboard.buttonCopiedLabel;
            } else {
              button.innerText = clipboard.buttonErrorLabel;
            }
            setTimeout(() => {
              button.innerText = clipboard.buttonLabel;
            }, 1500);
          })
          .catch((err) => {
            /* eslint-disable-next-line no-console */
            console.error(err);
          });
      });
    }
  },
  copyCode: async (block) => {
    const code = block.querySelector('code');
    if (code === null) {
      return false;
    }
    try {
      const codeClone = code.cloneNode(true);
      // Remove potential prefixes (+ or -) from diff-languages
      const prefixNodes = codeClone.querySelectorAll('.prefix');
      for (let i = 0; i < prefixNodes.length; i += 1) {
        prefixNodes[i].remove();
      }
      const text = codeClone.innerText;
      await navigator.clipboard.writeText(text);
      return true;
    } catch (err) {
      /* eslint-disable-next-line no-console */
      console.error(err);
      return false;
    }
  },
};

export default clipboard;
