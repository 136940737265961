const search = {
  pagefindLibrary: null,
  pagefindPath: '/pagefind/pagefind.js',
  form: null,
  input: null,
  results: null,
  resultsCount: null,
  resultsList: null,
  addResult: (result) => {
    const resultListItem = document.createElement('li');
    resultListItem.innerHTML = `<div>
  <a href="${result.url}"><strong>${result.meta.title}</strong></a>
  <p class="margin-block-start-1">${result.excerpt}</p>
</div>`;
    search.resultsList.append(resultListItem);
  },
  clearResults: () => {
    search.resultsCount.innerHTML = 'Results';
    search.resultsList.innerHTML = '';
  },
  getPagefindLibrary: async () => {
    if (search.pagefindLibrary === null) {
      await search.initPagefindLibrary();
    }
    return search.pagefindLibrary;
  },
  init: (searchForm) => {
    search.form = searchForm;
    search.form.addEventListener('submit', (event) => {
      event.preventDefault();
    });
    search.input = document.getElementById('js-search-input');
    search.initInput();
  },
  initInput: () => {
    if (search.input === null) {
      return;
    }

    search.results = document.getElementById('js-search-results');
    search.resultsCount = document.getElementById('js-search-results-count');
    search.resultsList = document.getElementById('js-search-results-list');
    // Initiliaze the Pagefind components on focus, so they are
    // ready once the user starts typing.
    search.input.addEventListener('focus', search.initPagefindLibrary);
    search.input.addEventListener('input', search.onInput);
  },
  initPagefindLibrary: async () => {
    if (search.pagefindLibrary === null) {
      search.pagefindLibrary = await import(search.pagefindPath);
      await search.pagefindLibrary.options({
        basePath: '/pagefind/',
      });
      search.pagefindLibrary.init();
      /* eslint-disable-next-line no-console */
      console.log('Pagefind Search Initiated');
    }
  },
  onInput: async (event) => {
    const pagefind = await search.getPagefindLibrary();
    const inputValue = event.target.value;

    if (inputValue.length > 2) {
      search.results.classList.remove('hidden');
      // Pagefind's debounced search preloads results as the user is typing,
      // while also waiting for a short momenbt before sending the query
      // after the user stopped typing (prevents unnecessary queries and
      // potential UI flickering).
      const query = await pagefind.debouncedSearch(inputValue);
      if (query === null) {
        // pagefind.debouncedSearch returns null if a subsequent
        // debouncedSearch call has been made within 300 ms.
        return;
      }

      // Pagefind returns all results as promises, which we await
      // together with `Promise.all`, then get the first 10 via `slice`.
      const results = await Promise.all(
        query.results.slice(0, 10).map((result) => result.data()),
      );

      search.clearResults();
      for (let i = 0; i < results.length; i += 1) {
        search.addResult(results[i]);
      }

      /* eslint-disable-next-line operator-linebreak */
      search.resultsCount.innerHTML =
        results.length !== 1 ? `${results.length} results` : '1 result';
      search.resultsCount.innerHTML += ` for "${inputValue}"`;
    } else {
      search.results.classList.add('hidden');
      search.clearResults();
    }
  },
};

export default search;
